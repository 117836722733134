<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <p-divider align="left">
    <b>{{ moduleString + '.generalInformation' | translate }}</b>
  </p-divider>
  <div class="formgrid grid p-fluid pt-2">
    <div class="col-12">
      <app-basic-input
        [label]="moduleFieldString + '.name.label' | translate: 'Name'"
        [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode != 'view' && (data?.status == 'DRAFT' || !data?.status) ? fieldViewMode : 'view'"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-12">
      <app-text-editor
        [height]="'100px'"
        [advanced]="true"
        [name]="'textarea'"
        [label]="moduleFieldString + '.description.label' | translate: 'Description'"
        [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Enter description...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode != 'view' && (data?.status == 'DRAFT' || !data?.status) ? fieldViewMode : 'view'"
      ></app-text-editor>
    </div>
    <div class="col-12 md:col-12">
      <app-text-editor
        [height]="'100px'"
        [advanced]="true"
        [name]="'details'"
        [label]="moduleFieldString + '.details.label' | translate: 'Details'"
        [placeholder]="moduleFieldString + '.details.placeholder' | translate: 'Enter Details...'"
        [control]="formGroup?.controls?.details"
        [viewMode]="fieldViewMode != 'view' && (data?.status == 'DRAFT' || !data?.status) ? fieldViewMode : 'view'"
      ></app-text-editor>
    </div>
  </div>

  <p-divider align="left">
    <b>{{ moduleString + '.standardDetails' | translate }}</b>
  </p-divider>
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-user-selector
        [label]="moduleFieldString + '.reviewedBy.label' | translate: 'reviewed By'"
        [placeholder]="moduleFieldString + '.reviewedBy.placeholder' | translate: 'Enter reviewedBy By'"
        [control]="formGroup?.controls?.reviewedBy"
        [optionValue]="'user'"
        [viewMode]="'view'"
      ></app-user-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-user-selector
        [label]="moduleFieldString + '.approvedBy.label' | translate: 'Approved By'"
        [placeholder]="moduleFieldString + '.approvedBy.placeholder' | translate: 'Enter Approved By'"
        [control]="formGroup?.controls?.approvedBy"
        [optionValue]="'user'"
        [viewMode]="'view'"
      ></app-user-selector>
    </div>
    <div class="col-12 md:col-12 mt-2">
      <app-user-and-group-selector
        [label]="moduleFieldString + '.owner.label' | translate: 'Owner'"
        [placeholder]="moduleFieldString + '.owner.placeholder' | translate: 'Enter Owner'"
        [principleMode]="true"
        [control]="formGroup?.controls?.owner"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="fieldViewMode != 'view' && (data?.status == 'DRAFT' || !data?.status) ? fieldViewMode : 'view'"
      >
      </app-user-and-group-selector>
    </div>

    <div class="col-12 md:col-6">
      <app-user-and-group-tree-selector
        [principleModeSelection]="'SELECTION'"
        [label]="moduleFieldString + '.reviewers.label' | translate: 'Reviewers'"
        [placeholder]="moduleFieldString + '.reviewers.placeholder' | translate: 'Enter Reviewers'"
        [control]="formGroup?.controls?.reviewers"
        [multi]="true"
        [viewMode]="fieldViewMode != 'view' && (data?.status == 'DRAFT' || !data?.status) ? fieldViewMode : 'view'"
        [selectedEntities]="data?.reviewers"
        [useResponsibilityMode]="true"
      >
      </app-user-and-group-tree-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-tree-selector
        [principleModeSelection]="'SELECTION'"
        [label]="moduleFieldString + '.approvers.label' | translate: 'Approvers'"
        [placeholder]="moduleFieldString + '.approvers.placeholder' | translate: 'Enter Approvers'"
        [control]="formGroup?.controls?.approvers"
        [multi]="true"
        [viewMode]="fieldViewMode != 'view' && (data?.status == 'DRAFT' || !data?.status) ? fieldViewMode : 'view'"
        [selectedEntities]="data?.approvers"
        [useResponsibilityMode]="true"
      >
      </app-user-and-group-tree-selector>
    </div>
    <div class="col-12">
      <app-target-code-selector
        [label]="moduleFieldString + '.controlObjectives.label' | translate: 'Control Objectives'"
        [placeholder]="moduleFieldString + '.controlObjectives.placeholder' | translate: 'Enter Control Objectives'"
        [control]="formGroup?.controls?.controlObjectives"
        [viewMode]="fieldViewMode != 'view' && (data?.status == 'DRAFT' || !data?.status) ? fieldViewMode : 'view'"
        [multi]="true"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [tableMode]="true"
        [customProjectionFields]="['code', 'recordStatus', 'label', 'name']"
        [targetTypes]="['CONTROL_OBJECTIVE']"
      >
      </app-target-code-selector>
    </div>
    <!-- @if(fieldViewMode!='create'){
        <div class="col-12 md:col-6">
            <app-target-code-selector [label]="'Policy Sections'"
                [placeholder]="'Search Policy Sections'"
                [control]="formGroup?.controls?.policySections"
                [viewMode]="'view'"
                [multi]="true"
                [optionLabel]="'name'"
                [optionValue]="'code'"
                [extraFilters]="[policyBodySectionFilter]"
                [customProjectionFields]="['code','recordStatus','label','name']"
                [targetTypes]="['POLICY_BODY_SECTION']">
            </app-target-code-selector>
        </div>
        <div class="col-12 md:col-6">
            <app-target-code-selector [label]="'Citations'"
                [placeholder]="'Search Citations'"
                [control]="formGroup?.controls?.citations"
                [viewMode]="'view'"
                [multi]="true"
                [optionLabel]="'name'"
                [optionValue]="'code'"
                [customProjectionFields]="['code','recordStatus','label','name']"
                [targetTypes]="['CITATION']">
            </app-target-code-selector>
        </div>
        } -->
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton && (data?.status == 'DRAFT' || !data?.status)) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton && (data?.status == 'DRAFT' || !data?.status)) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton && (data?.status == 'DRAFT' || !data?.status)) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
