import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseForm, DataTypeEnum, FilterItem, ModuleKeywords, PolicyBodySection, StandardDto } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-standard-item-form',
  templateUrl: './standard-item-form.component.html',
  styleUrl: './standard-item-form.component.scss',
})
export class StandardItemFormComponent extends BaseForm<StandardDto> implements OnInit {
  dataTypeEnum = DataTypeEnum;
  constructor(
    public viewModeService: ViewModeService,
    private router: Router
  ) {
    super(viewModeService, ModuleKeywords.Standard);
  }
  policyBodySectionFilter: FilterItem = {
    property: 'type',
    operation: 'EQUAL',
    value: PolicyBodySection.TypeEnum.ComplianceRequirements,
  };

  ngOnInit(): void { }

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      description: new FormControl(null),
      details: new FormControl(null),
      owner: new FormControl(null, Validators.required),
      approvers: new FormControl(null, Validators.required),
      reviewers: new FormControl(null, Validators.required),
      controlObjectives: new FormControl(null),
      reviewedBy: new FormControl(null),
      approvedBy: new FormControl(null)
    });
  }
  // resetFormGroup() {
  //   this.formGroup.reset();
  // }
}
